<template>
	<div>
		<br />
		<div class="col-sm-12 text-right">
			<h1>Facturas</h1>
		</div>
		<br />

		<DataGrid :config="dataGridConfig" :data="facturas" :select="seleccionar" @actions="dataGridActions" @ready="buscar_botones">
		<div class="col-md-2">
				<button class="btn warning-btn btn-recargar" @click="cargar_facturas">Recargar</button>
			</div>
			<div class="col-md-2">
				<button class="btn principal-btn btn-revisar" @click="pagar_facturas=true">Pagar facturas{{ total_facturas_pagar>0?' ('+total_facturas_pagar+')':'' }}</button>
			</div>
			<div class="col-md-2">
				<button class="btn secondary-btn btn-revisar" @click="revisar_factura">Revisar factura</button>
			</div>
			<div class="col-md-2"><button class="btn secondary-btn btn-revisar" @click="descarga_masiva">Descargar documentos</button></div>
			<div class="col-md-2">
				<div class="dropdown v-step-bt-4 btn-ayuda">
				  <button class="btn complementary-principal-btn dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				    Ayuda
				  </button>
				  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'interface')">Interface</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'detalles')">Ver detalles de facturas</a>
				    <a class="dropdown-item" href="#" @click="iniciar_tour($event, 'filtros')">Filtros</a>
				  </div>
				</div>
			</div>
		</DataGrid>

		<Modal v-if="factura" :options="{width: '80vw',close:true}" @close="cancelar_revisar_factura" class="bpb-modal">
			<h2 class="title">Factura</h2>
			<div class="body">
				<FacturaView :factura="factura" @observaciones="agregar_observacion"/>
			</div>
			<div v-if ="step_detalles != 0 || factura.estatus == 1 || factura.estatus == 5" class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-2" v-if="factura.estatus == 1 || step_detalles != 0">
							<button class="btn secondary-btn btn-cambiar-autorizador" @click="cambiar_autorizador_autorizadores">Cambiar de autorizador</button>
						</div>
						<div class="col-md-2" v-if="factura.estatus == 1 || step_detalles != 0">
							<button class="btn principal-btn btn-aprobar" @click="actualizar_factura(2)">Aprobar</button>
						</div>
						<div class="col-md-2" v-if="factura.estatus == 1 || step_detalles != 0">
							<button class="btn warning-btn btn-rechazar" @click="actualizar_factura(0)">Rechazar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="rechazar" class="bpb-modal" @close="rechazar=false">
			<h2 class="title">Rechazar factura</h2>
			<div class="body">
			    <div class="row">
			        <label for="razon_rechazo" class="col-form-label col-sm-2">Razón del rechazo</label>
			        <div class="col-sm-10">
			            <textarea v-model="observacion" name="razon_rechazo" id="razon_rechazo" cols="30" rows="10" class="form-control razon_rechazo"></textarea>
			        </div>
			    </div>
			    <br />
			    <div>
			        <div class="row">
			            <div class="col-sm-12 d-flex justify-content-end">
			                <div class="col-md-3">
			                    <button class="btn warning-btn" @click="rechazar_factura">Rechazar</button>
			                </div>
			                <div class="col-md-3">
			                    <button class="btn complementary-btn" @click="rechazar=false">Cancelar</button>
			                </div>
			            </div>
			        </div>
			    </div>
			</div>
		</Modal>

		<Modal v-if="pagar" :options="{width: '90vw',close:true}" @close="cancelar_pago" class="bpb-modal">
			<h2 class="title">Pagar factura</h2>
			<div class="body">
				<div class="row form-group">
					<label for="pago_monto" class="col-form-label col-sm-3">Monto</label>
					<div class="col-sm-9">
						<input v-model="pago.monto" type="text" name="pago_monto" id="pago_monto" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="pago_banco" class="col-form-label col-sm-3">Banco receptor</label>
					<div class="col-sm-9">
						<input v-model="pago.banco" type="text" name="pago_banco" id="pago_banco" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="cuenta" class="col-form-label col-sm-3">Cuenta receptor</label>
					<div class="col-sm-9">
						<input v-model="pago.cuenta" type="text" name="cuenta" id="cuenta" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="clabe_interbancaria" class="col-form-label col-sm-3">Clabe interbancaria receptor</label>
					<div class="col-sm-9">
						<input v-model="pago.clabe_interbancaria" type="text" name="clabe_interbancaria" id="clabe_interbancaria" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="titular" class="col-form-label col-sm-3">Titular</label>
					<div class="col-sm-9">
						<input v-model="pago.titular_cuenta" type="text" name="titular" id="titular" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="nombre_contacto" class="col-form-label col-sm-3">Nombre contacto</label>
					<div class="col-sm-9">
						<input v-model="pago.nombre_contacto" type="text" name="nombre_contacto" id="nombre_contacto" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="email_contacto" class="col-form-label col-sm-3">Email contacto</label>
					<div class="col-sm-9">
						<input v-model="pago.email_contacto" type="text" name="email_contacto" id="email_contacto" class="form-control">
					</div>
				</div>
				<div class="row form-group">
					<label for="pago_comprobante" class="col-form-label col-sm-3">Comprobante</label>
					<div class="col-sm-9">
						<input type="file" name="pago_comprobante" id="pago_comprobante">
					</div>
				</div>

				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-md-2">
							<button class="btn principal-btn btn-finalizar-pago" @click="pagar_factura">Pagar</button>
						</div>
						<div class="col-md-2">
							<button class="btn complementary-btn" @click="cancelar_pago">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="cambiar_autorizador_modal" @close="cambiar_autorizador_modal=false">
			<div class="title">Cambiar autorizador</div>
			<div class="body">
				<div class="row form-group">
					<label for="autorizadores_actuales" class="col-form-label col-sm-2">Autorizadores actuales</label>
					<div class="col-sm-10">
						<table style="width: 100% !important">
							<thead style="background-color: #000 !important;color: #fff;">
								<th style="width:35px">#</th>
								<th>Autorizador</th>
							</thead>
							<tbody>
								<tr v-for="(autorizador,index) in autorizador_actual">
									<td>{{ (index+1) }}</td>
									<td>{{ autorizador }}</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<div class="row form-group">
					<label for="nuevo_autorizador" class="col-form-group col-sm-2">Autorizadores</label>
					<div class="col-sm-10">
						<select v-model="nuevo_autorizador" name="nuevo_autorizador" id="nuevo_autorizador" class="form-control">
							<option v-for="autorizador in autorizadores" :key="autorizador.id" :value="autorizador.id">{{ autorizador.nombre }} ({{ autorizador.area }})</option>
						</select>
					</div>
				</div>
			</div>
			<div class="footer">
				<div class="row">
					<div class="col-sm-12 d-flex justify-content-end">
						<div class="col-sm-2 mr-2">
							<button class="btn principal-btn btn-cambiar-finalizar" @click="cambiar_autorizador">Cambiar</button>
						</div>
						<div class="col-sm-2">
							<button class="btn secondary-btn" @click="cambiar_autorizador_modal=false">Cancelar</button>
						</div>
					</div>
				</div>
			</div>
		</Modal>

		<Modal v-if="pagar_facturas" :options="{width: '90vw'}">
			<div class="title">Pre-pago de facturas</div>
			<div class="body">
				<Pago @factura="revisar_factura_pago" @cerrar="pagar_facturas=false" :factura="factura" />	
			</div>
			<div class="footer"></div>
		</Modal>

		<DescargaMasiva v-if="modal_descarga_masiva" :facturas="seleccionadas" @cancelar="cerrar_descarga_masvia" />

		<v-tour name="tour-interface" :steps="steps_inteface" :options="steps_options"></v-tour>
		<v-tour name="tour-detalles" :steps="filtros_detalles_steps" :options="steps_options" :callbacks="steps_callbacks_detalles"></v-tour>
		<v-tour name="tour-filtros-facturas" :steps="filtros_factura_steps" :options="steps_options" :callbacks="steps_callbacks_filtros"></v-tour>
	</div>
</template>

<script type="text/javascript">
	import DataGrid from '@/components/DataGrid'
	import Modal from '@/components/Modal'
	import FacturaView from '@/apps/tesoreria/components/FacturasView'
	import {VMoney} from 'v-money'
	import Pago from './Factura/Pago'

	import account from '@/api/account'
	import api from '@/apps/tesoreria/api/tesoreria'
	import apiPagos from '@/apps/tesoreria/api/pagos'

	import DescargaMasiva from './Factura/DescargaMasvia'

	export default {
		components: {
			DataGrid, Modal, VMoney, FacturaView, Pago, DescargaMasiva
		}
		,data: () => ({
			dataGridConfig: {
				name: 'facturas'
				,cols: {
					razon_social_emisor: 'Proveedor'
					,proveedor_id: 'Representante'
					,uuid: 'UUID'
					,folio: 'Folio'
					,total: 'Total'
					,created_at: 'Fecha de subida'
					,metodo_pago: 'Método de pago'
					,revisada: 'Pre-Autorización'
					,estatus: 'Estatus'
					,id: {
						text: 'Documentos'
						,ordeable: false
					}
				}
				,filters: {
					cols: {
						id: 'ID'
						,empresa_id: {
							text: 'Proveedor'
							,options: {}
						}
						,proveedor_id: {
							text: 'Representante'
							,options: {}
						}
						,uuid: 'UUID'
						,metodo_pago: 'Metodo de pago'
						,total: 'Total'
						,subtotal: 'Subtotal'
						,serie: 'Serie'
						,folio: 'Folio'
						,estatus: {
							text: 'Estatus'
							,options: {
								0: 'Rechazada'
								,1: 'Sin revisar'
								,2: 'Aceptada sin revisión'
								,3: 'En revisión por director de Finanzas'
								,4: 'En revisión por director de General'
								,5: 'En proceso de pago'
								,6: 'Pagada'
							}
						}
						,created_at: 'Fecha de creación'
					}
				}
				,paginator: {
					pagina_actual: 1
					,total_registros: 1
					,registros_por_pagina: 20
				}
				,mutators: {
					empresa_id: function(val, row) {
						return row.empresa ? row.empresa.empresa : 'Sin especificar';
					}
					,uuid(val, row, vue) {
						return '<span title="'+val+'">'+(val.substring(0,10))+'...</span>';
					}
					,total: function(val, row, vue) {
						// val = parseFloat(val);
						return '$'+vue.$helper.moneyFormat(val);
					}
					,subtotal: function(val, row, vue) {
						return '$'+vue.$helper.moneyFormat(val);
					}
					,proveedor: function(val) {
						return val.nombre;
					}
					,revisada: function(val) {
						switch(val) {
							case 0:
								return '<span class="factura_validada" style="color: #929292;font-weight:bold;">Sin validar</span>';
								break
							case 1:
								return '<span class="factura_validada" style="color: #31992A;font-weight:bold;">Validada</span>';
								break;
							case 2:
								return '<span class="factura_validada" style="color: #E09431;font-weight:bold;">Inconsistencia</span>';
								break;
						}
					}
					,estatus: function(val, row, dataGrid, vuex) {
						let mssg = '';
						switch(val) {
							case 0:
								mssg = '<span style="color:#FA3030;font-weight:bold">Rechazada</span>';
								break;
							case 1:
								mssg = '<span style="color:#4545FF;font-weight:bold">Sin revisar</span>';
								break;
							case 2:
								mssg = '<span style="color:#FF9E44;font-weight:bold">Aceptada sin revisión</span>';
								break;
							case 3:
								mssg = '<span style="color:#4AD627;font-weight:bold">En revisión por director de Finanzas</span>';
								break;
							case 4:
								mssg = '<span style="color:#5A96E2;font-weight:bold">En revisión por director de Finanzas y director General</span>';
								break;
							case 5:
								mssg = '<span style="color:#5A96E2;font-weight:bold">En proceso de pago</span>';
								break;
							case 6:
								mssg = '<span style="color:#5A96E2;font-weight:bold">Pagada</span>';
								break;
						}

						return mssg
					}
					,id: function(val, row, vue) {
						return '<button class="btn principal-btn" name="download_files" value="'+val+'" data-json="'+encodeURI(JSON.stringify(row))+'">Descargar</button>';
					}
					,proveedor_id: function(val, row) {
						return row.proveedor_nombre;
					}
					,created_at(val,row,vue) {
						return vue.$moment(val).format('dd DD MMM YYYY HH:mm:ss');
					}
				}
			}
			,options: {
				page: 1
				,order_col: 'id'
				,order_dir: 'desc'
				,limit: 20
				,filters: []
			}
			,facturas: []
			,factura: null
			,seleccionadas: []
			,seleccionar: false
			,observacion: null
			,rechazar: false
			,pagar: false
			,pago: {
				banco: null
				,cuenta: null
				,monto: null
			}
			,cambiar_autorizador_modal: false
			,autorizadores: []
			,nuevo_autorizador: null
			,autorizador_actual: []
			,pagar_facturas: false
			,total_facturas_pagar: 0
			,steps_options: {
				labels: {
					buttonSkip: 'Finalizar'
					,buttonPrevious: 'Anterior'
					,buttonNext: 'Siguiente'
					,buttonStop: 'Finalizar'
				}
			}
			,step_inteface: 0
			,steps_inteface: [
				{
					target: '.btn-recargar'
					,content: 'Permite recargar el contenido manteniendo la configuración actual (filtros, paginado, orden, etc)'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: '.btn-ayuda'
					,content: 'Muestra información del dashboard y lista las guías de la aplicación'
				}
				,{
					target: 'table.table-striped'
					,content: 'Grid donde se muestra y ordena la información'
				}
				,{
					target: 'button[name="download_files"]'
					,content: 'Permite descargar los documentos de la factura indicada'
				}
			]
			,steps_callbacks_detalles: {}
			,step_detalles: 0
			,filtros_detalles_steps: [
				{
					target: 'label:not([for="facturas_all"])'
					,content: 'Seleccionamos una factura'
				}
				,{
					target: '.btn-revisar'
					,content: 'Muestra información detallada de la factura seleccionada'
				}
				,{
					target: 'label[for="proveedor"]'
					,content: 'Se visualiza información más detallada de la factura'
				}
				,{
					target: 'label[for="conceptos"]'
					,content: 'Listamos todos los conceptos de la factura'
				}
				,{
					target: 'label[for="impuestos"]'
					,content: 'Listamos todos los impuestos de la factura'
				}
				,{
					target: 'label[for="pagos"]'
					,content: 'Cuando la factura ya tiene pagos se listarán aquí'
				}
				,{
					target: 'label[for="observaciones"]'
					,content: 'Se listan las observaciones que se van agregando según el proceso que lleve la factura, también podrás ver observaciones que agreguen los operadores de BienParaBien'
				}
				,{
					target: '.agregar_observacion_btn'
					,content: 'Puedes agregar observaciones extras siempre y cuando la factura aún no sea pagada'
				}
				,{
					target: '.btn-cambiar-autorizador'
					,content: 'Permite cambiar el autorizador actual de la factura'
				}
				,{
					target: 'label[for="autorizadores_actuales"]'
					,content: 'Se lista los autorizadores que tiene actualmente la factura'
				}
				,{
					target: 'label[for="nuevo_autorizador"]'
					,content: 'Se lista los autorizadores que estan disponibles para cambiar'
				}
				,{
					target: '.btn-cambiar-finalizar'
					,content: 'Finalmente realizamos el cambio'
				}
				,{
					target: '.btn-aprobar'
					,content: 'Mueve la factura a su siguiente etapa'
				}
				,{
					target: '.btn-rechazar'
					,content: 'Rechaza la factura y no puede continuar su proceso'
				}
				,{
					target: '.btn-pagar'
					,content: 'Puedes agregar observaciones extras siempre y cuando la factura aún no sea pagada'
				}
				,{
					target: 'label[for="pago_monto"]'
					,content: 'Indica el monto del pago, en caso de ser PPD puedes indicar un monto inferior'
				}
				,{
					target: 'label[for="pago_banco"]'
					,content: 'Nombre del banco a donde se realizara el pago'
				}
				,{
					target: 'label[for="cuenta"]'
					,content: 'Número de cuenta a donde se realizará el pago'
				}
				,{
					target: 'label[for="clabe_interbancaria"]'
					,content: 'Clave interbancaria a donde se realizará el pago'
				}
				,{
					target: 'label[for="titular"]'
					,content: 'Nombre del titular de la cuenta'
				}
				,{
					target: 'label[for="nombre_contacto"]'
					,content: 'Nombre del contacto de la cuenta'
				}
				,{
					target: 'label[for="email_contacto"]'
					,content: 'Email del contacto de la cuenta'
				}
				,{
					target: 'label[for="pago_comprobante"]'
					,content: 'Para realizar el pago se requiere el comprobante bancario del pago en cuestión'
				}
				,{
					target: '.btn-finalizar-pago'
					,content: 'Finalmente enviamos el pago al sistema'
				}
			]
			,steps_callbacks_filtros: {}
			,step_filtros: 0
			,filtros_factura_steps: [
				{
					target: '#dataGrid-btn-add-filter'
					,content: 'Los filtros funcionan para limitar los registros a visualizar, especialmente cuando se busca un registro o registros en particular, para agregar un filtro comenzamos dando click al botón "Agregar filtro"'
				}
				,{
					target: '#filtro_columna'
					,content: 'Seleccionamos la columna a la que se le aplicará el filtro'
				}
				,{
					target: '#filtro_operador'
					,content: 'Seleccionamos el operador que se aplicará al filtro'
				}
				,{
					target: '#valor1'
					,content: 'Indicamos el valor que tendrá el filtro, esto podría ser unicamente texto o en caso de que la columna sea de un tipo en especifico deberá tener un formato en especial, por ejemplo, para fechas el formato tendrá que ser YYYY-MM-DD HH:ii:ss, puede ser solo la fecha, la fecha y hora o solo la hora, algunos operadores pueden requerir más de un dato'
				}
				,{
					target: '.dataGrid-filter-btn-add'
					,content: 'Finalmente damos click en el botón agregar o cancelar en caso que ya no desees agregar filtros'
				}
			]
			,modal_descarga_masiva: false
		})
		,created: function() {
			// this.cargar_facturas();
			this.cargar_estatus();
			this.cargar_empresas();

			this.steps_callbacks_detalles = {
				onStart: this.tour_detalles_next
				,onNextStep: this.tour_detalles_next
				,onPreviousStep: this.tour_detalles_prev
				,onStop: this.tour_detalles_stop
			}

			this.steps_callbacks_filtros = {
				onNextStep: this.tour_filtros_facturas
				,onPreviousStep: this.tour_filtros_facturas_prev
				,onStop: this.tour_filtros_facturas_stop
			}
		}
		,mounted: function() {
			this.cargar_facturas_pagar();
		}
		,methods: {
			cargar_facturas() {
				api.obtener_facturas(this.options)
				.then(res => {
					this.$log.info('res', res);
					this.facturas = res.data.data;

					for(let i=0; i<res.data.data.length; i++) {
						this.dataGridConfig.filters.cols.proveedor_id.options[res.data.data[i].proveedor_id] = res.data.data[i].proveedor_nombre;
					}

					this.dataGridConfig.paginator.pagina_actual = res.data.current_page;
					this.dataGridConfig.paginator.total_registros = res.data.total;
					this.dataGridConfig.paginator.registros_por_pagina = parseInt(res.data.per_page);
				})
				.catch(err => {
					this.$log.info('err', err);

					this.$helper.showAxiosError(err, 'Error al obtener facturas');
				})
			}
			,cargar_estatus: function() {
				if (!this.$store.state.factura_status)
					api.cargar_estatus()
					.then(res => {
						this.$log.info('res', res);
						this.$store.commit('set', {factura_status: res.data});
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
			}
			,cargar_empresas: async function() {
				try {
					let empresas = (await api.listar_empresas()).data;

					for(let i=0; i<empresas.length; i++) {
						this.dataGridConfig.filters.cols.empresa_id.options[empresas[i].id] = empresas[i].empresa;
					}
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,cargar_facturas_pagar: async function() {
				try {
					this.total_facturas_pagar = (await apiPagos.total_facturas()).data;
				}catch(e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,dataGridActions: function(tipo, data) {
				this.$log.info('tipo', tipo);
				if (tipo == 'options') {
					this.options = data;
					this.cargar_facturas();
				}else {
					this.seleccionadas = data;
					this.seleccionar = true;
				}
			}
			,buscar_botones: function() {
				this.$log.info('buscando botones');
				document.querySelectorAll('button[name=download_files]').forEach(btn => {
					// this.$log.info('btn', btn);
					btn.removeEventListener('click',this.descargar_archivos);
					btn.addEventListener('click', this.descargar_archivos);
				});
			}
			,descargar_archivos: async function(e) {
				let factura = JSON.parse(decodeURI(e.target.getAttribute('data-json')));

				try {
					let res = (await api.descargar_documentos_v2(factura.id)).data;
					let domain = process.env.VUE_APP_API_TESORERIA;
          domain = domain.replace('api/v1','storage');
          window.open(domain+'/'+res.file);
				}catch (e) {
					this.$log.info('err', e);
					this.$helper.showAxiosError(e,'Error');
				}
			}
			,revisar_factura: function() {
				if (this.seleccionadas.length > 0) {
					this.factura = this.seleccionadas[0];
					this.$log.info('factura', this.factura);
				}else {
					this.$helper.showAxiosError('Tienes que seleccionar una factura', 'Error');
				}
			}
			,cancelar_revisar_factura: function() {
				this.factura = null;
				this.seleccionar = false;
			}
			,actualizar_factura: function(estatus) {
				if (this.step_detalles == 0 || this.step_detalles == 14) {
					if (estatus == 2) {
						this.factura.estatus = estatus;

						api.actualizar_factura(this.factura.id, this.factura)
						.then(res => {
							this.$log.info('res', res);
							this.factura = null;
							this.seleccionar = false;
							this.cargar_facturas();
						})
						.catch(err => {
							this.$log.error('err', err);

							this.$helper.showAxiosError(err, 'Error al actualizar factura');
						})
					}else if (estatus == 6) {
						this.pagar = true;
						this.pago = this.factura.empresa ? this.factura.empresa : {banco: null,cuenta: null,monto: null};
						let monto = this.factura.total;
						let pagos = 0;

						for(let i=0; i<this.factura.pagos.length; i++) {
							pagos += this.factura.pagos[i].monto;
						}

						this.pago.monto = this.$helper.moneyFormat(this.factura.total - pagos);
					}else {
						this.rechazar = true;
					}
				}
			}
			,agregar_observacion: function(observacion) {
				if (observacion && observacion.length > 5) {
					api.enviar_observaciones_factura(this.factura.id, {observacion: observacion})
					.then(res => {
						this.$log.info('res', res);
						this.factura.observaciones = res.data;
						// this.observacion = null;
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err,'Error');
					})
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,rechazar_factura: async function() {
				if (this.observacion && this.observacion.length > 5) {
					await this.agregar_observacion(this.observacion);

					this.factura.estatus = 0;
					await api.actualizar_factura(this.factura.id, this.factura)
					.then(res => {
						this.$log.info('res', res);

						this.rechazar = false;
						this.observacion = null;
						this.cancelar_revisar_factura();
						this.cargar_facturas();
					})
					.catch(err => {
						this.$log.info('err', err);
						this.$helper.showAxiosError(err, 'Error al rechazar factura');
					})
				}else {
					this.$helper.showAxiosError('Tienes que enviar una observación mas descriptiva (mínimo 5 caracteres)', 'Error');
				}
			}
			,pagar_factura: function() {
				let monto = document.getElementById('pago_monto').value;
				monto = monto.split(',');
				monto = monto.join('');
				monto = monto.replace('.00','');
				this.$log.info('pago', monto);

				if (monto <= 0) {
					this.$helper.showMessage('Error','Tienes que definir el monto a pagar', 'error', 'alert');
					return false;
				}

				if (monto > this.factura.total) {
					this.$helper.showMessage('Error','El monto a pagar no puede ser mayor al total de la factura', 'error', 'alert');
					return false;
				}

				if (document.getElementById('pago_comprobante').files.length == 0) {
					this.$helper.showMessage('Error','Debes agregar el comprobante de pago bancario', 'error', 'alert');
					return false;
				}

				let formData = new FormData();
				formData.append('monto',monto);
				formData.append('banco',this.pago.banco);
				formData.append('cuenta',this.pago.cuenta);
				formData.append('clabe_interbancaria',this.pago.clabe_interbancaria);
				formData.append('titular_cuenta',this.pago.titular_cuenta);
				formData.append('nombre_contacto',this.pago.nombre_contacto);
				formData.append('email_contacto',this.pago.email_contacto);
				formData.append('documento',document.getElementById('pago_comprobante').files[0]);

				api.pagar_factura(this.factura.id, formData)
				.then(res => {
					this.$log.info('res', res);
					this.cancelar_pago();
					this.seleccionar = false;
					this.factura = null;
					this.cargar_facturas();
				})
				.catch(err => {
					this.$log.info('err', err);
					this.$helper.showAxiosError(err,'Error');
				})
			}
			,cancelar_pago: function() {
				this.pagar = false;
				// this.factura = null;
				this.pago = {
					banco: null
					,monto: null
					,cuenta: null
				}

				let comprobante = document.getElementById('pago_comprobante')

				if (comprobante)
					comprobante.value = null;
			}
			,cambiar_autorizador_autorizadores: async function() {
				if (this.step_detalles == 0 || this.step_detalles == 8) {
					this.cambiar_autorizador_modal = true;

					if (this.autorizadores.length == 0)
						this.autorizadores = (await api.obtener_aprobadores()).data;

					this.autorizador_actual = [];

					if (this.factura.autorizador)
						this.autorizador_actual.push(this.factura.autorizador.nombre);
					else if (this.factura.proveedor)
						for(let i=0; i<this.factura.proveedor.autorizadores.length; i++) {
							this.autorizador_actual.push(this.factura.proveedor.autorizadores[i].nombre);
						}
					else
						this.autorizador_actual.push('Desconocido');

					this.nuevo_autorizador = this.autorizadores[0].id;
				}
			}
			,cambiar_autorizador: async function() {
				if (this.step_detalles == 0) {
					try {
						let res = (await api.modificar_autorizador_factura(this.factura.id, this.nuevo_autorizador)).data;
						this.cambiar_autorizador_modal = false;

						this.cargar_facturas();
						let id = this.factura.id;

						this.$helper.showMessage('Autorizador cambiado','Se logró cambiar el autorizador correctamente');
					}catch(e) {
						this.$log.info('error', e);
						this.$helper.showAxiosError(e,'Error');
					}
				}
			}
			,iniciar_tour: function(e, tour) {
				e.preventDefault();

				switch(tour) {
					case 'interface':
						this.$tours['tour-interface'].start();
						break;
					case 'detalles':
						this.$tours['tour-detalles'].start();
						break;
					case 'filtros':
						this.$tours['tour-filtros-facturas'].start();
						break;

				}
			}
			,tour_filtros_facturas: function(paso) {
				this.$log.info('step filtros', paso);
				this.step_filtros = paso;

				if (paso == 0)
					document.getElementById('dataGrid-btn-add-filter').click();

				if (paso >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(paso) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_facturas_prev: function() {
				this.step_filtros -= 1;
				this.$log.info('step filtros prev', this.step_filtros);

				if (this.step_filtros >= 0) {
					setTimeout(() => {
						let top = 0;
						switch(this.step_filtros) {
							case 0:
								top = '-127px';
								break;
							case 1:
								top = '-127px';
								break;
							case 2:
								top = '-319px'
								break;
						}

						document.querySelector('div.v-step').style.top = top;
					},20)
				}
			}
			,tour_filtros_facturas_stop: function() {
				let btn_cancel = document.querySelector('button.dataGrid-filter-btn-cancel');

				if (btn_cancel)
					btn_cancel.click();

				this.step_filtros = 0;
			}
			,tour_detalles_next: function(step) {
				this.$log.info('step',step)
				this.step_detalles = step;

				if (typeof(step) == 'undefined')
					document.querySelector('input[type="checkbox"][name="facturas"]').click();

				switch(step) {
					case 1:
						this.revisar_factura();
						break;
					case 8:
						this.cambiar_autorizador_autorizadores();
						break;
					case 11:
						this.cambiar_autorizador_modal = false;
						break;
					case 14:
						this.actualizar_factura(6);
						break;
				}

				setTimeout(() => {
					switch(step) {
						case 8:
							document.querySelector('div.v-step').style.top = '-124px';
							break;
						case 9:
							document.querySelector('div.v-step').style.top = '-51px';
							break;
					}
				},20)
			}
			,tour_detalles_prev: function(step) {
				this.step_detalles -= 1;
				this.$log.info('step',this.step_detalles)

				if (this.step_detalles == 0) {
					this.cancelar_revisar_factura();
					setTimeout(() => {
						document.querySelector('input[type="checkbox"][name="facturas"]').click();
					},20);
				}

				setTimeout(() => {
					switch(this.step_detalles) {
						case 7:
							this.cambiar_autorizador_modal = false;
							break;
						case 8:
							document.querySelector('div.v-step').style.top = '-124px';
							break;
						case 9:
							document.querySelector('div.v-step').style.top = '-51px';
							break;
						case 10:
							this.cambiar_autorizador_autorizadores();
							break;
						case 13:
							this.cancelar_pago();
							break;
					}
				},20)
			}
			,tour_detalles_stop: function() {
				this.step_detalles = 0;
				this.cancelar_revisar_factura();
				this.cancelar_pago();
				this.cambiar_autorizador_modal = false;
			}
			,revisar_factura_pago: function(factura) {
				this.factura = factura;
			}
			,descarga_masiva() {
				if (this.seleccionadas.length == 0) {
					this.$helper.showMessage('Error','Debes seleccionar al menos una factura','error','alert');
					return;
				}

				this.modal_descarga_masiva = true;
			}
			,cerrar_descarga_masvia() {
				this.modal_descarga_masiva = false;
				this.seleccionar = false;
			}
		}
		,computed: {
			total_procesada: function() {
				return '$'+this.factura.total
			}
			,subtotal_procesada: function() {
				return '$'+this.factura.subtotal
			}
			,obtener_metodo_pago: function() {
				let metodo = '';

				switch(this.factura.metodo_pago) {
					case 'PUE':
						metodo = 'Pago en una sola exhibición (PUE)';
						break;
					case 'PPD':
						metodo = 'Pago en parcialidades o diferido (PPD)';
						break;
				}

				return metodo;
			}
			,total_factura: function() {
				return this.$helper.moneyFormat(this.factura.total);
			}
		}
		,watch: {
			pago: {
				handler: function(val,oldVal) {
					this.$log.info('pago', val.monto);
					if (val.monto) {
						let monto = val.monto;
						monto = monto.split(',').join('');
						this.pago.monto = this.$helper.moneyFormat(monto);
					}
				}
				,deep: true
			}
		}
	}
</script>

<style lang="scss" scope>
	.razon_rechazo {
		width: 100%;
		height: 170px !important;
		resize: none;
	}
</style>